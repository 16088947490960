.videos-report__wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.videos-report__controls {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  align-items: center;
  display: flex;
  flex-flow: wrap;
  background-color: #fff;
  padding: 16px 32px;
  border-bottom: 1px solid var(--color-p1-4);
}

.videos-report__table table {
  border-collapse: separate;
}

.videos-report__table tbody td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.videos-report__table .tide-table-column-header--sortable > span::after,
.videos-report__table .tide-table-column-header--sorting-asc > span::after {
  top: 5px;
}

.videos-report__thumbnail-and-title {
  display: flex;
  align-items: center;
}

.videos-report__thumbnail {
  position: relative;
  background-color: black;
  height: 48px;
  width: 85px;
  border-radius: 3px;
}

.videos-report__thumbnail img {
  height: 48px;
  margin: auto;
  display: block;
  max-width: 85px;
  object-fit: cover;
  border-radius: 3px;
}

.videos-report__title {
  margin-left: 16px;
  font-family: ProximaNova-Semibold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: var(--color-p1-10);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}

.videos-report__loading-user {
  border: 1px solid red;
}
